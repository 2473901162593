import React, { useState } from "react";
import BackSlider from "../../../components/Login/BackSlider";
import CardRound from "../../../components/Login/CardRound";
import bg1 from "../../../../src/images/login/bgs/bg-1.jpg";
import { FaCopy, FaMailBulk } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../../components/Login/Loading";
import { Link } from "gatsby";
import PopupRound from "../../../components/Login/PopupRound";
import SEO from "../../../components/SEO";

const ResultAssign = props => {
    const [userData, setUserData] = useState(
        window.localStorage.getItem("dados-assinatura")?.length > 0
            ? JSON.parse(window.localStorage.getItem("dados-assinatura"))
            : null
    );

    const { ClipboardItem } = window;
    const [data, setData] = useState(props.location?.state);
    const [isLoading, setisLoading] = useState(false);
    const [isCopy, setisCopy] = useState(false);
    const [isHelp, setisHelp] = useState(false);
    const copyText = () => {
        setisCopy(true);
        try {
            let text = document
                .getElementById("iframe")
                .contentWindow.document.getElementById("assinatura-vendemmia").innerHTML;
            const type = "text/html";
            const blob = new Blob([text], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            // navigator.clipboard.writeText(content);
            navigator.clipboard.write(data).then(
                () => {
                    /* success */
                    toast.success("Texto copiado para a área de transferência");
                    setisCopy(false);
                },
                () => {
                    /* failure */
                    toast.error("Não foi possivel copiar automaticamente, por favor, realize o processo manualmente.");
                    setisCopy(false);
                }
            );
        } catch (err) {
            toast.error("Não foi possivel copiar automaticamente, por favor, realize o processo manualmente.");
        }
    };

    const bgs = [
        {
            urlBg: bg1,
            text: "Agora é só copiar para adicionar ao seu email!",
            timeSecs: 90,
            topicId: null, // 1- Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
            filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
        },
        {
            urlBg: bg1,
            text: "Agora é só copiar para adicionar ao seu email!",
            timeSecs: 90,
            topicId: null, // 1- Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
            filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
        },
    ];
    return (
        <BackSlider slides={bgs} className="assin-result">
            <SEO title="Assinatura de e-mail - resultado" />

            <CardRound hideMobile={true}>
                <h1 className="text-center mb-5">A assinatura foi criada, agora basta copiar e adicionar ao seu email!</h1>
                <iframe
                    id="iframe"
                    src={`https://analytics.vendemmia.com.br/assinatura/generate.php?name=${userData.name}&field=${userData.office}&mail=${userData.email}&phone=${userData.phone}&address=${userData.address}`}
                />
                <footer>
                    <Link to={`/assinatura`} className="remember" state={data}>
                        Voltar
                    </Link>
                    <button className={`btn`} onClick={() => setisHelp(!isHelp)}>
                        <FaMailBulk className="mr-2" /> Como configuro?
                    </button>
                    <button className={`btn ${isCopy ? "copy" : ""}`} style={{ display: "none" }} onClick={() => copyText()}>
                        <FaCopy className="mr-2" /> Copiar
                    </button>
                </footer>
            </CardRound>
            <ToastContainer />
            <Loading status={isLoading} />
            <PopupRound status={isHelp} title="Como configurar sua assinatura">
                <ol>
                    <li>Copie todo o conteúdo (Ctrl + A, Ctrl + C)</li>
                    <li>
                        Se você estiver com o outlook instalado no computador
                        <ul>
                            <li>Acesse, no canto superior esquerdo, "Arquivo" {">"} "Opções" {">"} "E-mail" {">"} "Assinatura".</li>
                            <li>Preencha o espaço da assinatura colando o que foi copiado.</li>
                        </ul>
                    </li>
                    <li>
                        Se você usa a versão online, no navegador
                        <ul>
                            <li>Acesse, no canto superior direito a engrenagem de configurações</li>
                            <li>Use a caixa de pesquisa para pesquisar "Assinatura"</li>
                            <li>Abra o item "Assinatura de Email"</li>
                            <li>Preencha o espaço da assinatura colando o que foi copiado.</li>
                        </ul>
                    </li>
                </ol>
            </PopupRound>
        </BackSlider>
    );
};

export default ResultAssign;
